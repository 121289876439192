import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatTooltipModule,
  MatMenuModule,
  MatTabsModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatButtonToggleModule,
  MatExpansionModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatDividerModule,
  MatRippleModule
 } from '@angular/material';
import { CommonModule } from '@angular/common';

const material = [
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  CommonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatTooltipModule,
  MatMenuModule,
  MatTabsModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatButtonToggleModule,
  MatExpansionModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatDividerModule,
  MatRippleModule
 ];

@NgModule({

  imports: [material],
  exports: [material]
})
export class MaterialModule { }
