import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'buy',
    loadChildren: () => import('./buy/buy.module').then(mod => mod.BuyModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(mod => mod.RegisterModule)
  },
  {
    path: 'otp/:mobile/:name',
    loadChildren: () => import('./otp/otp.module').then(mod => mod.OtpModule)
  },
  {
    path: 'password',
    loadChildren: () => import('./password/password.module').then(mod => mod.PasswordModule)
  },
  {
    path: 'email/:mobile',
    loadChildren: () => import('./email/email.module').then(mod => mod.EmailModule)
  },
  {
    path: 'reset/:mobile',
    loadChildren: () => import('./resetpassword/resetpassword.module').then(mod => mod.ResetpasswordModule)
  },
  {
    path: 'tests',
    loadChildren: () => import('./onlinetest/onlinetest.module').then(mod => mod.OnlinetestModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exams/:title/:examMasterId',
    loadChildren: () => import('./examdetail/examdetail.module').then(mod => mod.ExamdetailModule),
    canActivate: [AuthGuard]
   } ,
   {
    path: 'instruction/:type/:examId',
    loadChildren: () => import('./instruction/instruction.module').then(mod => mod.InstructionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'SingleQuestion/:examId',
    loadChildren: () => import('./testpage/testpage.module').then(mod => mod.TestpageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'MultipleQuestions/:examId',
    loadChildren: () => import('./testpageMultiple/testpageMultiple.module').then(mod => mod.TestpageMultipleModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'result/:examMasterId/:examId',
    loadChildren: () => import('./resultpage/resultpage.module').then(mod => mod.ResultPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'resultDashboard/:examMasterId/:examId',
    loadChildren: () => import('./result-dashboard/result-dashboard.module').then(mod => mod.ResultDashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then(mod => mod.EditProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(mod => mod.AboutModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then(mod => mod.TermsModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then(mod => mod.PrivacyModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('.././pages/jobs/jobs.module').then(mod => mod.JobsModule)
  },
  {
    path: 'exam-material',
    loadChildren: () => import('.././pages/user-material/user-material.module').then(mod => mod.UserMaterialModule)
  },
  {
    path: 'videolist',
    loadChildren: () => import('./videos/video-list/video-list.module').then(mod => mod.VideoListModule)
  },
  {
    path: 'video-detail',
    loadChildren: () => import('./videos/video-detail/video-detail.module').then(mod => mod.VideoDetailModule)
  },
  {
    path: 'ca-detail',
    loadChildren: () => import('.././pages/current-affairs/ca-detail/ca-detail.module').then(mod => mod.CaDetailModule)
  },
  {
    path: 'ca-list',
    loadChildren: () => import('.././pages/current-affairs/ca-listing/ca-listing.module').then(mod => mod.CaListingModule)
  },
  {path: 'construction',  loadChildren: () => import('./construction/construction.module').then(mod => mod.ConstructionModule)},
  {path: '404',  loadChildren: () => import('./not-found/not-found.module').then(mod => mod.NotFoundModule)},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    enableTracing: false,
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
