
import { CoolLocalStorage } from 'angular2-cool-storage';
import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor( @Inject(PLATFORM_ID) private platformId: Object, private localStorage: CoolLocalStorage) { }
  addMenuClass() {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById('menuId').classList.add('menu-bg');
  }
  }
  removeFooter() {
    if (isPlatformBrowser(this.platformId)) {
    document.getElementById('footer1').style.display = 'none';
    document.getElementById('footer').style.marginTop = '0px';
    }
  }
  addFooter() {
    if (isPlatformBrowser(this.platformId)) {
    document.getElementById('footer1').style.display = 'block';
    document.getElementById('footer').style.marginTop = '-100px';
    }
  }
  removeFullFooter() {
    if (isPlatformBrowser(this.platformId)) {
    document.getElementById('footer').style.display = 'none';
    document.getElementById('nav-header').style.display = 'none';
    }
  }
  addHeader() {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById('nav-header').style.display = 'block';
      document.getElementById('footer').style.display = 'block';
      }
  }
  addFullFooter() {
    if (isPlatformBrowser(this.platformId)) {
    document.getElementById('footer').style.display = 'block';
    document.getElementById('nav-header').style.display = 'block';
    }
  }
  currentUserValue(): any {
    const data: any = this.localStorage.getObject('userInfo');
    return data.UserDTO;
 }
 getUserID() {
  const data: any = this.localStorage.getObject('userInfo');
  return data.UserDTO.UserID;
 }
 getUserName() {
  const data: any = this.localStorage.getObject('userInfo');
  return data.UserDTO.UserName;
 }
 getUserImage() {
  const data: any = this.localStorage.getObject('userInfo');
  return data.UserDTO.ImagePath;
 }
 checkUserExist() {
   const currentUser: any = this.localStorage.getObject('userInfo');
   if (currentUser != null) {
    return true;
   } else {
     return false;
   }
 }
}
