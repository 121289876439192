import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoolStorageModule } from 'angular2-cool-storage';
import { ConfirmModalComponent } from './shared/confirm-modal/confirm-modal.component';
import { ErrorModalComponent } from './shared/error-modal/error-modal.component';
import { ConfirmTestModalComponent } from './shared/confirm-testmodal/confirm-testmodal.component';
import { SuccessModalComponent } from './shared/success-modal/success-modal.component';
import { CommonService } from 'src/services/common.service';
import { ExamPopUpComponent } from './shared/exam-popup/exam-popup.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './shared/interceptor/tokenInterceptor';
import { LoginService } from 'src/services/login.service';
import { LayoutService } from 'src/services/layout.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SEOService } from 'src/services/seo.service';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [
    AppComponent, ConfirmModalComponent, ErrorModalComponent, ConfirmTestModalComponent, SuccessModalComponent,
    ExamPopUpComponent
  ],
  imports: [
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    CoolStorageModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    JwSocialButtonsModule
  ],
  providers: [CommonService, LoginService, LayoutService,SEOService, {  provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ConfirmModalComponent, ErrorModalComponent, ConfirmTestModalComponent, SuccessModalComponent,
  ExamPopUpComponent]
})
export class AppModule { }
