import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
@Component({
  selector: 'app-exam-modal',
  templateUrl: './exam-popup.component.html',
  styleUrls: ['./exam-popup.component.scss']
})
export class ExamPopUpComponent implements OnInit {
  config: any;
  total: any;
  examName: string;
  examArray:any;
  constructor(
    public dialogRef: MatDialogRef<ExamPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.examArray = data.examData;
      this.total=data.total;
      this.examName=data.examName;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
  ok() {
    this.dialogRef.close({ data: 'ok' });
  }
}
