import { Injectable, Output, EventEmitter, PLATFORM_ID, Inject  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoolLocalStorage } from 'angular2-cool-storage';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  localStorage: CoolLocalStorage;
  apiUrl = '';
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient,
              localStorage: CoolLocalStorage) {
    this.localStorage = localStorage;
    this.apiUrl = environment.API_Url;
    this.currentUserSubject = new BehaviorSubject<any>(localStorage.getObject('userInfo'));
    this.currentUser = this.currentUserSubject.asObservable();
  }
   currentUserValue(): any {
     return this.localStorage.getObject('userInfo');
  }
  login(userModel) {
    return this.http.post<any>(this.apiUrl + 'login', userModel)
        .pipe(map(user => {
            if (user) {
                this.localStorage.setObject('userInfo', user);
                this.currentUserSubject.next(user);
                this.hideLogin();
            }
            return user;
  }));
  }
  logout() {
     this.localStorage.removeItem('userInfo');
     this.showLogin();
  }
  getAuthToken(): string {
    const currentUser: any = this.localStorage.getObject('userInfo');
    if (currentUser != null) {
      return currentUser.token;
    }
    return '';
  }
  refreshToken()  {
    const currentUser: any = this.localStorage.getObject('userInfo');
    const token = currentUser.refreshToken;
    return this.http.post<any>(this.apiUrl + 'refreshtoken', { token: currentUser.token, refreshToken: currentUser.refreshToken })
      .pipe(
        map(user => {
        const userInfo: any = this.localStorage.getObject('userInfo');
        if (user && user.token) {
          userInfo.token = user.token;
          userInfo.refreshToken = user.refreshToken;
          this.localStorage.setObject('userInfo', userInfo);
          }
        return user;
      }));
  }
  hideLogin() {
    if (isPlatformBrowser(this.platformId)) {
    if (document.getElementById('login') != null) {
      (document.getElementById('login') as HTMLInputElement).style.display = 'none';  
     }
    if (document.getElementById('loginMob') != null) {
     (document.getElementById('loginMob') as HTMLInputElement).style.display = 'none'; 
    }
    if (document.getElementById('logout') != null) {
      (document.getElementById('logout') as HTMLInputElement).style.display = 'block';
    }
    if (document.getElementById('logoutMob') != null) {
      (document.getElementById('logoutMob') as HTMLInputElement).style.display = 'block';
    }
  }
  }
  showLogin() {
    if (isPlatformBrowser(this.platformId)) {
    if (document.getElementById('logout') != null) {
      (document.getElementById('logout') as HTMLInputElement).style.display = 'none'; 
    }
    if (document.getElementById('logoutMob') != null) {
      (document.getElementById('logoutMob') as HTMLInputElement).style.display = 'none';
    }
    if (document.getElementById('login') != null) {
      (document.getElementById('login') as HTMLInputElement).style.display = 'block';
    }
    if (document.getElementById('loginMob') != null) {
      (document.getElementById('loginMob') as HTMLInputElement).style.display = 'block';
    }
  }
  }
  checkUser() {
    const currentUser: any = this.localStorage.getObject('userInfo');
    if (currentUser != null) {
      this.hideLogin();
    } else {
      this.showLogin();
    }
  }
  savePush(subscriber) {
    return this.http.post(this.apiUrl + 'push', subscriber);
  }
  getHomeSelect() {
    return this.http.get(this.apiUrl + 'homeSelect');
  }
  verifyEmail(mobile) {
    return this.http.get(this.apiUrl + 'verifyemail/' + mobile);
  }
}
