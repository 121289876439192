import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ModalData } from '../modal-data';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  info: any;
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData) {
      this.info = data;
    }

    onSubmit(): void {
      this.dialogRef.close({ data: 'Submit' });
    }
    onCancel(): void {
      this.dialogRef.close({ data: 'Cancel' });
    }

  ngOnInit() {
  }

}
