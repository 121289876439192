import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  constructor() { }
  public readonly VAPID_PUBLIC_KEY = 'BDJR10i1QFLRONCSlMLOchdY1OKsqpNSTzZJcvLwyCe1QQv33HinCu_umG1vfrYEBcE3Ds7vOb68yQKc47k7kyc';
  public readonly VAPID_PRIVATE_KEY = 'at4Po-HQRMa-BoetRUjzur0PdS9ZAFqzNbHguje-eTk';
  public readonly ClientImageUrl = 'https://api.dharshus.com/resources/images/';
  public readonly FileExamImageUrl = 'https://files.freeexamcoaching.com/ExamImages/';
  public readonly CommonImageUrl = 'https://files.freeexamcoaching.com/common/';
  //public readonly ClientImageUrl = 'https://localhost:44333/resources/images/';
  //public readonly DummyImage = 'https://api.dharshus.com/resources/images/no-image.png';
  public readonly DummyImage = 'image 10.svg';
  public readonly DummyProfileImage = '/assets/images/dummy-big.svg';
  public readonly DummyTopperImage = '/assets/images/user.svg';
  public readonly DummyExamMasterImage = '/assets/images/examCard-icon.svg';
  public readonly logoImage = '/assets/images/logo.svg';
  public readonly benefitsTitle = 'Benefits of Dharshus';
  public readonly appDownloadTitle = 'Download Dharshus';
  public readonly IsPartnerSection = true;
  public readonly fbLink = 'https://www.facebook.com/FreeExamCoaching';
  public readonly instaLink = '';
  public readonly youLink = '';
  public readonly twitterLink = '';
  public readonly IsOTPScreen=false;
  public readonly whatsAppLink = 'https://web.whatsapp.com/send?phone=919751921236&amp;text=Hi there, I have a question';
}
  
