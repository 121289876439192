import {
  Component,
  HostListener,
  ChangeDetectorRef,
  OnInit,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import {
  BreakpointObserver
} from '@angular/cdk/layout';
import {
  Router
} from '@angular/router';
import {
  LoginService
} from 'src/services/login.service';
import {
  CommonService
} from 'src/services/common.service';
import {
  SwPush,
  SwUpdate
} from '@angular/service-worker';
import {
  timingSafeEqual
} from 'crypto';
import { LayoutService } from 'src/services/layout.service';
import { MatMenuTrigger } from '@angular/material';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  // @ViewChild('clickHoverMenuTrigger') clickHoverMenuTrigger: MatMenuTrigger,any;
  panelOpenState = false;
  menuClass = 'container-fluid';
  ismobileScreen = false;
  sidenav: any;
  currentUser: any;
  flagLoggedIn = false;
  displayNav = 'hideSideNav';
  VAPID_PUBLIC_KEY: string;
  fbLink = '';
  instaLink = '';
  youLink = '';
  twitterLink = '';
  whatsAppLink = '';
  logoImage = '';
  username = '';
  userImage = '';
  dummyProfileImg = '';
  clientImageUrl = '';
  menuItem = [];
  constructor(private loginService: LoginService, private layoutService: LayoutService,
              private cdRef: ChangeDetectorRef, private router: Router, private commonService: CommonService,
              private swUpdate: SwUpdate, private swPush: SwPush) {
                this.logoImage = this.layoutService.logoImage;
                this.fbLink = this.layoutService.fbLink;
                this.instaLink = this.layoutService.instaLink;
                this.youLink = this.layoutService.youLink;
                this.twitterLink = this.layoutService.twitterLink;
                this.whatsAppLink = this.layoutService.whatsAppLink;
                this.dummyProfileImg = this.layoutService.DummyProfileImage;
                this.clientImageUrl = this.layoutService.ClientImageUrl;
                this.VAPID_PUBLIC_KEY = this.layoutService.VAPID_PUBLIC_KEY;
                this.swUpdate.available.subscribe(evt => {
                  this.swUpdate.activateUpdate().then(() => document.location.reload());
                });
  }

  ngOnInit() {
    this.getHome();
    this.subscribeToNotifications();
    this.displayNav = 'hideSideNav';
    this.username = this.commonService.getUserName();
    this.userImage = this.commonService.getUserImage();
  }
  subscribeToNotifications() {
    // if (this.swPush.isEnabled) {

    // } else {
    this.swPush.notificationClicks.subscribe();
    this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      })
      .then(sub => this.getAuthFCMKey(sub))
      .catch(err => console.error('Could not subscribe to notifications', err));
    // }
  }
  ngAfterViewInit() {
    this.checkUser();
  }
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    const pageOffSet = window.pageYOffset;
    if (pageOffSet > 90) {
      this.menuClass = 'slidemenu';
    } else {
      this.menuClass = 'container-fluid';
    }
  }
  toggle() {
    this.checkDashboardNav();
    this.checkUser();
    if (this.ismobileScreen) {
      this.ismobileScreen = false;
      this.displayNav = 'hideSideNav';
    } else {
      this.ismobileScreen = true;
      this.displayNav = 'showSideNav';
    }
  }
  checkDashboardNav() {}
  signout() {
    this.loginService.logout();
    this.currentUser = null;
    this.router.navigate(['home']);
  }
  checkUser() {
    this.loginService.checkUser();
  }
  getAuthFCMKey(subscription: any) {
    const subscribeData: any = {};
    subscribeData.FCMEndPoint = subscription.endpoint;
    subscribeData.P256dhKey = subscription.toJSON().keys.p256dh;
    subscribeData.AuthKey = subscription.toJSON().keys.auth;
    subscribeData.RequestType = 'Web';
    this.saveSubscriber(subscribeData);
  }
  saveSubscriber(subscriber) {
    this.loginService.savePush(subscriber)
      .subscribe(data => {},
        error => {
          console.log(error);
        });
  }
  redirectLogin() {
    this.router.navigate(['/login']);
  }
  getHome() {
    this.loginService.getHomeSelect()
      .subscribe(data => {
        const menuItem: any = data;
        this.menuItem = menuItem.menuDTOs;
      },
        error => {
          console.log(error);
        });
  }
}
