import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class SEOService {
  apiUrl = '';
  constructor(private title: Title, private meta: Meta) { }
  updateMetaTags(ogTitle: string,title: string, url: string, desc: string,keyword: string,img: string) {
    this.title.setTitle(ogTitle);
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ property: 'og:description', content: desc });
    this.meta.updateTag({property: 'keywords', content: keyword});
    this.meta.updateTag({property: 'og:image', content: img});
  }
}
